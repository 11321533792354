import { configureStore } from '@reduxjs/toolkit';

const ACTION_TYPES = /** @type {const} */ ({
  SET_SECTION: 'SET_SECTION',
  SET_PAGE: 'SET_PAGE',
  SET_HEADER: 'SET_HEADER',
  SET_LOADING: 'SET_LOADING',
  SET_CONTACT_DETAILS: 'SET_CONTACT_DETAILS',
});

// Action definition
export const SET_SECTION = ACTION_TYPES.SET_SECTION;
export const SET_PAGE = ACTION_TYPES.SET_PAGE;
export const SET_HEADER = ACTION_TYPES.SET_HEADER;
export const SET_LOADING = ACTION_TYPES.SET_LOADING;
export const SET_CONTACT_DETAILS = ACTION_TYPES.SET_CONTACT_DETAILS;

/**
 * @typedef {ACTION_TYPES[keyof typeof ACTION_TYPES]} ActionType
 */

/**
 * @typedef {Object} Action
 * @property {ActionType} type
 */

/**
 * @typedef {DONATION_SECTION[keyof typeof DONATION_SECTION]} DonationSection
 */

export const DONATION_SECTION = /** @type {const} */({
  CATEGORIES: 'category',
  AMOUNT_DONATION_VALUES: 'amount',
  AMOUNT_DONATION_CUSTOM: 'custom-amount',
  CONFIRM_AMOUNT: 'confirm-amount',
  CONFIRM_LEAVE_MESSAGE: 'confirm-leave-message',
  LEAVE_MESSAGE: 'leave-message',
  PAYMENT_WIDGET: 'widget',
  GIFT_AID_CONFIRM: 'gift-success-message',
  GIFT_AID: 'giftAid-form',
  SUBSCRIBE: 'subscribe',
  TERMS_CONDITIONS: 'terms-condition',

  MESSAGE_INFO: 'message-info',
  MESSAGE_SUCCESS: 'message-success',
  MESSAGE_ERROR: 'message-error',
});

/**
 * @typedef {Object} AppStateHeader
 * @property {String} title
 * @property {boolean} showCancel
 * @property {*} backSection
 */

/**
 * @typedef {Object} AppState
 * @property {*} section
 * @property {*} message
 * @property {*} backSection
 * @property {*} giftAidSection
 * @property {*} page
 * @property {AppStateHeader} header
 * @property {Boolean} loading
 * @property {Boolean} contactDetailsFlow
 */

/**
 * @type {AppState}
 */
const defaultState = Object.freeze({
  section: null,
  message: null,
  backSection: null,
  giftAidSection: null,
  page: null,
  header: {
    title: '',
    showCancel: false,
    backSection: '',
  },
  loading: false,
  contactDetailsFlow: false,
});

let previousState = { ...defaultState };

/**
 * @param {AppState} currentState 
 * @param {Action} action 
 * @returns {AppState}
 */
const mainReducer = (currentState = defaultState, action) => {
  // if( action.type !== SET_HEADER){
  //   console.groupCollapsed('-- TRACE --', action.type, "From ", stateLocal.section, " To ", action.section);
  //   console.trace()
  //   console.groupEnd();
  //   //console.log("stateLocal ", stateLocal, previousState)
  // }


  previousState = { ...currentState }; // Store previous state

  switch (action.type) {
  case SET_SECTION: {
    const loading = action.loading ? action.loading : false;
    return {
      ...currentState,
      section: action.section,
      message: action.message,
      backSection: action.backSection,
      giftAidSection: action.giftAidSection,
      loading,
    };
  }
  case SET_PAGE:
    return { ...currentState, page: action.page };

  case SET_HEADER:
    return {
      ...currentState,
      header: {
        title: action.title,
        showCancel: action.showCancel,
        backSection: action.backSection,
      },
    };

  case SET_LOADING:
    return {
      ...currentState,
      loading: action.loading,
    };

  case SET_CONTACT_DETAILS:
    return {
      ...currentState,
      contactDetailsFlow: action.contactDetailsFlow,
    };

  default:
    return currentState;
  }
};

/**
 * Key used for local storage data persistence.
 */
const persistedStateKey = '__store';

/**
 * Gets a store previously added to localStorage
 * @returns {AppState | undefined}
 */
function getPersistedState() {
  const storedValue = localStorage.getItem(persistedStateKey);

  if (!storedValue) {
    return undefined;
  }

  // Load/save the state to localstorage when the page first loads.
  // This is triggered explicitly from a call rather than automatically as
  // we usually want the flow to start with an empty state.
  try {
    return JSON.parse(storedValue);
  } catch {
    return undefined;
  } finally {
    // Remove the local state now that it's been loaded. We only want
    // to persist state when explicitly required, otherwise the app
    // would incorrectly store state indefinitely.
    localStorage.removeItem(persistedStateKey);
  }
}

const store = configureStore({
  preloadedState: getPersistedState(),
  reducer: mainReducer,
});

/**
 * Persists the store to local storage once.
 */
function persistStoreBeforeReload() {
  localStorage.setItem(persistedStateKey, JSON.stringify(store.getState()));
}

const subscribe = (callbackFunc) => {
  const callbackLocal = callbackFunc;
  return store.subscribe(() => callbackLocal(store.getState(), previousState));
};
const dispatch = (type) => store.dispatch(type);
const getState = () => store.getState();

// Actions
export const setHeader = (options) => store_webpay.dispatch({ type: SET_HEADER, ...options });

export const setLoading = (loading = true) => store_webpay.dispatch({ type: SET_LOADING, loading });

export const setSection = ({
  section, backSection, giftAidSection, loading, message,
}) => store_webpay.dispatch({
  type: SET_SECTION, section, backSection, giftAidSection, loading, message,
});

export const setContactDetails = (contactDetailsFlow) => store_webpay.dispatch({ type: SET_CONTACT_DETAILS, contactDetailsFlow });

export const store_webpay = {
  dispatch,
  getState,
  setContactDetails,
  subscribe,
  persistStoreBeforeReload,
};
