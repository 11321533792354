import React from 'react';
import { DONATION_SECTION } from './store';
import { showUINext } from './common';
import { FadeInTransition } from './transition';
import PropTypes from 'prop-types';

Categories.propTypes = {
  show: PropTypes.bool
};


export function Categories(props) {
  const [categoryList, setCategoryList] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const onShow = (showLocal) => {
    // set category title
    if (showLocal) {
      // -------------
      // INIT: CHECKINGS
      // -------------

      // No Categories configuration (i.e donation_category_flag === 0)
      if (window.charityDetail.donation_category_flag === 0) { // donation category flag OFF
        let categoryData = null;
        // Check if default category exists
        if (window.charityDetail.donation_category !== undefined) {
          const category = window.charityDetail.donation_category.find((item) => item.is_default === 1);
          if (category) { categoryData = { ...category }; }
        }

        // Category is already selected by default
        onCategoryClickDelayed(categoryData);
        return;
      }

      // Find default category
      const defaultCategoryCandidate = window.charityDetail.donation_category.find((item) => item.is_default === 1);

      // Find all visible categories
      const visibleCategoryList = window.charityDetail.donation_category.filter((item) => item.visible === 1);

      // If there is no visible category, Redirect to amount list page with default Category
      if (visibleCategoryList.length === 0) {
        onCategoryClickDelayed(defaultCategoryCandidate);
        return;
      }

      // Categories configuration (i.e donation_category_flag === 1 and custom values)
      if (window.charityDetail.tab_initial_payment_flag === 1
        && window.charityDetail.donation_category.length === 0) {
        // Category is already selected by default
        onCategoryClickDelayed(null);
        return;
      }

      // -------------
      // END: CHECKINGS
      // -------------

      // Show or Hide UI
      setCategoryList(visibleCategoryList);
    }

    // Set show
    setShow(showLocal);
  };

  const onCategoryClickDelayed = (category) => {
    setTimeout(() => {
      onCategoryClick(category);
    }, 200);
  };

  const onCategoryClick = (category, showBackBtn = false) => {
    if (category === null) category = { category_name: 'General', is_giftaided: 0 };

    // GA Tag
    window.dataLayer.push({
      event: 'CategorySelected',
      virtualPageURL: '/webpay/donationcategory/',
      virtualPageTitle: 'User selected the category of Donation',
    });
    // set category value
    window.userData.category.name = escape(category.category_name);
    window.userData.category.is_giftaided = category.is_giftaided;

    const backSection = showBackBtn ? DONATION_SECTION.CATEGORIES : null;
    showUINext({ backSection });
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  let txt_category_screen = 'What’s your donation for?';
  if (window.charityDetail) txt_category_screen = window.charityDetail.custom_text_field.txt_category_screen;
  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="categories">
        <FadeInTransition show={show}>
          <div className="ch_heading_1 txt_primary">{txt_category_screen}</div>
          <div className="category">
            {categoryList.map((item, idx) => (
              <button
                key={idx}
                className="btn ch_btn category-btn"
                label="category"
                onClick={() => onCategoryClick(item, true)}
              >
                {item.category_name}
              </button>
            ))}
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
