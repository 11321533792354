// import $ from "jquery";
// import "jquery-validation"
// import "jquery-validation/dist/additional-methods.js"
// import * as jquery_ui from "jquery-ui"
import React, { useCallback } from 'react';
import { easeInOutBack } from 'js-easing-functions';
import { events } from './events';
import { showUI, showUINext } from './common';
import { DONATION_SECTION } from './store';
import { FadeInTransition, useInterval } from './transition';
// import { useIntervalv2 } from "./transition";
import PropTypes from 'prop-types';


GiftAid.propTypes = {
  show: PropTypes.bool,
  section: PropTypes.string
};

/**
 * @typedef {GIFTAID_SECTION[keyof typeof GIFTAID_SECTION]} GiftAidSection
 */

export const GIFTAID_SECTION = /** @type {const} */ ({
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  ADDRESS: 'address',
  POSTCODE: 'postcode',
  EMAIL: 'email',
});

const GIFT_AID_SECTION_STATE = /** @type {const} */ ({
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
  FADE_IN_NEXT: 'FADE_IN_NEXT',
  FADE_OUT_NEXT: 'FADE_OUT_NEXT',
  FADE_IN_PREVIOUS: 'FADE_IN_PREVIOUS',
  FADE_OUT_PREVIOUS: 'FADE_OUT_PREVIOUS',
});

export function GiftAid(props) {
  const [show, setShow] = React.useState(false);
  const [sectionCurrent, setSectionCurrent] = React.useState(GIFTAID_SECTION.FIRSTNAME);
  const [sectionList, setSectionList] = React.useState([
    {
      name: GIFTAID_SECTION.FIRSTNAME,
      value: '',
      valid: false,
      state: GIFT_AID_SECTION_STATE.HIDDEN,
      maxlength: 35,
      label: 'First name',
      placeholder: 'Enter first name',
    },
    {
      name: GIFTAID_SECTION.LASTNAME,
      value: '',
      valid: false,
      state: GIFT_AID_SECTION_STATE.HIDDEN,
      maxlength: 35,
      label: 'Last name',
      placeholder: 'Enter last name',
    },
    {
      name: GIFTAID_SECTION.ADDRESS,
      value: '',
      valid: false,
      state: GIFT_AID_SECTION_STATE.HIDDEN,
      maxlength: 40,
      label: 'House name or number',
      placeholder: 'Enter house name',
    },
    {
      name: GIFTAID_SECTION.POSTCODE,
      value: '',
      valid: false,
      state: GIFT_AID_SECTION_STATE.HIDDEN,
      maxlength: 8,
      label: 'Postcode',
      placeholder: 'Enter postcode',
    },
    {
      name: GIFTAID_SECTION.EMAIL,
      value: '',
      valid: false,
      state: GIFT_AID_SECTION_STATE.HIDDEN,
      maxlength: 45,
      label: 'Email',
      placeholder: 'Enter email address',
    },
  ]);
  const [errorMsg, setErrorMsg] = React.useState(null);

  // Transition
  // const PROGRESS_TRANSITION_STEP = 1
  // const PROGRESS_TRANSITION_DELAY = 3
  const PROGRESS_TRANSITION_MAX = 100;
  // const PROGRESS_TRANSITION_MIN = 0
  const [progressTransition, setProgressTransition] = React.useState(PROGRESS_TRANSITION_MAX);

  const onShow = (showLocal, giftAidSection) => {
    if (showLocal) {
      setErrorMsg(null);
      setSectionCurrent(giftAidSection);
      const sectionListLocal = [...sectionList];
      const sectionLocal = sectionListLocal.find((item) => item.name === giftAidSection);
      sectionLocal.state = GIFT_AID_SECTION_STATE.VISIBLE;
      setSectionList(sectionListLocal);
    }

    // Show or Hide UI
    setShow(showLocal);
  };

  const reset = useCallback(() => {
    setSectionCurrent(GIFTAID_SECTION.FIRSTNAME);
    const sectionListLocal = [...sectionList];
    sectionListLocal.map((item) => item.state = GIFT_AID_SECTION_STATE.HIDDEN);
    sectionListLocal.map((item) => item.value = '');
    const sectionLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.FIRSTNAME);
    sectionLocal.state = GIFT_AID_SECTION_STATE.VISIBLE;
    setSectionList(sectionListLocal);
  }, [sectionList]);

  // React.useEffect(() => {
  //   onFormInitialisation()
  //   return () => onValidatorDestroy()
  // }, [onFormInitialisation, onValidatorDestroy])

  React.useEffect(() => {
    events.on('reset', reset);

    return () => {
      events.off('reset', reset);
    };
  }, [reset]);

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show, props.section);
  });

  const isTransitionOngoing = () => {
    const transitionSectionList = sectionList.filter((item) => [GIFT_AID_SECTION_STATE.VISIBLE, GIFT_AID_SECTION_STATE.HIDDEN].includes(item.state) === false);
    return transitionSectionList.length > 0;
  };

  const PROGRESS_MAX = 100;
  const PROGRESS_MIN = 0;
  const PROGRESS_STEP = 2;
  const PROGRESS_DELAY = 25;
  const [progress, setProgress] = React.useState(PROGRESS_MAX);
  const delay = progress <= PROGRESS_MAX ? PROGRESS_DELAY : null;
  useInterval({
    callback: () => {
      const value = easeInOutBack(progress, PROGRESS_MIN, 1, PROGRESS_MAX);
      setProgressTransition(1.0 - value);
      // setProgress for useIntervalv2
      setProgress(progress + PROGRESS_STEP);
    },
    completed: () => {
      // Make fade_in_* visible and hidden others
      const sectionListLocal = [...sectionList];
      const sectionFadeIn = sectionListLocal.find((item) => [GIFT_AID_SECTION_STATE.FADE_IN_NEXT, GIFT_AID_SECTION_STATE.FADE_IN_PREVIOUS].includes(item.state));
      sectionListLocal.map((item) => item.state = GIFT_AID_SECTION_STATE.HIDDEN);
      sectionFadeIn.state = GIFT_AID_SECTION_STATE.VISIBLE;
      setSectionList(sectionListLocal);
    },
    delay,
    progress,
    progress_step: PROGRESS_STEP,
    progress_max: PROGRESS_MAX,
  });

  const launchAnimation = () => {
    setProgressTransition(1.0);
    setProgress(PROGRESS_MIN);
  };

  const onNext = () => {
    setErrorMsg(null);
    // check no transition
    if (isTransitionOngoing()) return;

    // When section is email no transition
    if (sectionCurrent === GIFTAID_SECTION.EMAIL) {
      for (const section of sectionList) window.userData.donor[section.name] = section.value;
      showUINext();
      return;
    }

    // Launch animation
    launchAnimation();

    // Modify visibility
    const sectionListLocal = [...sectionList];
    const sectionFromLocal = sectionListLocal.find((item) => item.name === sectionCurrent);
    sectionFromLocal.state = GIFT_AID_SECTION_STATE.FADE_OUT_NEXT;

    if (sectionCurrent === GIFTAID_SECTION.FIRSTNAME) {
      // Set FADE_IN_NEXT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.LASTNAME);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_NEXT;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.LASTNAME);
    }
    if (sectionCurrent === GIFTAID_SECTION.LASTNAME) {
      // Set FADE_IN_NEXT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.ADDRESS);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_NEXT;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.ADDRESS);
    }
    if (sectionCurrent === GIFTAID_SECTION.ADDRESS) {
      // Set FADE_IN_NEXT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.POSTCODE);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_NEXT;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.POSTCODE);
    }
    if (sectionCurrent === GIFTAID_SECTION.POSTCODE) {
      // Set FADE_IN_NEXT/FADE_OUT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.EMAIL);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_NEXT;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.EMAIL);
    }

    // Set section list
    setSectionList(sectionListLocal);
  };
  const onPrevious = () => {
    setErrorMsg(null);
    // check no transition
    if (isTransitionOngoing()) return;

    // When in first name redirect to confirm
    if (sectionCurrent === GIFTAID_SECTION.FIRSTNAME) {
      // Set next section
      showUI(DONATION_SECTION.GIFT_AID_CONFIRM);
      return;
    }

    // Launches the animation
    launchAnimation();

    // Modify visibility
    const sectionListLocal = [...sectionList];
    const sectionFromLocal = sectionListLocal.find((item) => item.name === sectionCurrent);
    sectionFromLocal.state = GIFT_AID_SECTION_STATE.FADE_OUT_PREVIOUS;

    if (sectionCurrent === GIFTAID_SECTION.LASTNAME) {
      // Set FADE_IN/FADE_OUT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.FIRSTNAME);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_PREVIOUS;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.FIRSTNAME);
    }
    if (sectionCurrent === GIFTAID_SECTION.ADDRESS) {
      // Set FADE_IN/FADE_OUT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.LASTNAME);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_PREVIOUS;
      // Set next section
      setSectionCurrent(GIFTAID_SECTION.LASTNAME);
    }
    if (sectionCurrent === GIFTAID_SECTION.POSTCODE) {
      // Set FADE_IN/FADE_OUT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.ADDRESS);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_PREVIOUS;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.ADDRESS);
    }
    if (sectionCurrent === GIFTAID_SECTION.EMAIL) {
      // Set FADE_IN/FADE_OUT Section
      const sectionToLocal = sectionListLocal.find((item) => item.name === GIFTAID_SECTION.POSTCODE);
      sectionToLocal.state = GIFT_AID_SECTION_STATE.FADE_IN_PREVIOUS;

      // Set next section
      setSectionCurrent(GIFTAID_SECTION.POSTCODE);
    }

    // Set section list
    setSectionList(sectionListLocal);
  };

  const onGiftAidChange = (section, valueStr) => {
    const sectionListLocal = [...sectionList];
    const sectionLocal = sectionListLocal.find((item) => item.name === section);
    sectionLocal.value = valueStr;
    sectionLocal.valid = true;

    // Validate
    let error_msg = null;
    if (sectionCurrent === GIFTAID_SECTION.FIRSTNAME) {
      if (valueStr.length === 0) {
        sectionLocal.valid = false;
        error_msg = 'Please enter first name.';
      }
    } else if (sectionCurrent === GIFTAID_SECTION.LASTNAME) {
      if (valueStr.length === 0) {
        sectionLocal.valid = false;
        error_msg = 'Please enter last name.';
      }
    } else if (sectionCurrent === GIFTAID_SECTION.ADDRESS) {
      if (valueStr.length === 0 || valueStr.length > 40) {
        sectionLocal.valid = false;
        error_msg = 'Please mention address.';
      }
    } else if (sectionCurrent === GIFTAID_SECTION.POSTCODE) {
      // const regex = new RegExp('^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$')
      const regex = new RegExp('^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA)[ -]?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2}[ -]?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$', 'i');
      if (regex.test(valueStr) === false) {
        sectionLocal.valid = false;
        error_msg = 'Please enter a valid postcode.';
      }
    } else if (sectionCurrent === GIFTAID_SECTION.EMAIL) {
      const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
      if (regex.test(valueStr) === false) {
        sectionLocal.valid = false;
        error_msg = 'Please mention valid email address.';
      }
    }
    if( /\p{Extended_Pictographic}/u.test(valueStr) ){
      sectionLocal.valid = false;
      error_msg = 'Do not use special characters.';
    }

    setSectionList(sectionListLocal);
    setErrorMsg(error_msg);
  };

  // INIT: Print status of sections
  // const statusStr = sectionList.map( section => `${section.name}:${section.state } `).join(' / ')
  // console.log(statusStr)
  // END: Print status of sections
  // console.log("progressTransition ", progressTransition)

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="giftaid">
        <FadeInTransition show={show}>
          { sectionList.map((section, idx) => {
            // Check whether element is visible
            const visible = section.state !== GIFT_AID_SECTION_STATE.HIDDEN;

            // Visible/Hidden variables
            let opacity = section.state === GIFT_AID_SECTION_STATE.VISIBLE ? 1 : 0;
            let left = '0%';
            let transform = 'scale(1.0)';

            // -- Animation NEXT --
            if (section.state === GIFT_AID_SECTION_STATE.FADE_IN_NEXT) {
              opacity = 1;
              left = `${progressTransition * 70}%`; // left  50% -> 0%
            }
            if (section.state === GIFT_AID_SECTION_STATE.FADE_OUT_NEXT) {
              opacity = progressTransition; // opacity -> 1 -> 0
              const scaleLocal = 0.8 + progressTransition * 0.2;
              transform = `scale(${scaleLocal})`; // scale 1.0 -> 0.8
            }
            // -- Animation NEXT --

            // -- Animation PREVIOUS --
            if (section.state === GIFT_AID_SECTION_STATE.FADE_IN_PREVIOUS) {
              opacity = 1 - progressTransition; // opacity -> 0 -> 1
              const scaleLocal = 0.8 + (1 - progressTransition) * 0.2;
              transform = `scale(${scaleLocal})`; // scale 0.8 -> 1.0
            }
            if (section.state === GIFT_AID_SECTION_STATE.FADE_OUT_PREVIOUS) {
              opacity = progressTransition; // opacity -> 1 -> 0
              left = `${(1 - progressTransition) * 70}%`; // left -> 0% -> 70%
            }
            // -- Animation PREVIOUS --

            const isAnimating = progressTransition !== 0;

            return (
              <div
                key={idx}
                id={`${section.name}React`}
                className={`giftaid-field ${visible ? '' : 'd-none'}`}
                style={{ opacity, left, transform }}
                data-isanimating={isAnimating}
              >

                <form className="form-group needs-validation" id="firstNameForm">
                  <div className="form_sm">
                    <label htmlFor={`field-${section.name}`}>{section.label}</label>
                    <input
                      id={`field-${section.name}`}
                      type="text"
                      className={`form-control ch_form_lg text-left ${errorMsg ? 'activate_error' : ''}`}
                      placeholder={section.placeholder}
                      maxLength={section.maxlength}
                      value={section.value}
                      name={section.name}
                      onChange={(e) => onGiftAidChange(section.name, e.target.value)}
                    />
                    {errorMsg ? <div className="activate_error">{errorMsg}</div> : null}
                  </div>
                </form>

                <div className="backNext-btn-div pt-5">
                  <button
                    className="btn ch_btn backNext-btn"
                    onClick={() => onPrevious()}
                  >
                          Previous
                  </button>
                  <button
                    className="btn ch_btn backNext-btn"
                    onClick={() => onNext()}
                    disabled={!section.valid}
                  >
                          Next
                  </button>
                </div>
              </div>
            );
          })}
        </FadeInTransition>
      </div>
    </div>
  );
}
export default GiftAid;
