import React from 'react';
import { FadeInTransition } from './transition';
// import $ from "jquery";
import { showUINext, showUI } from './common';
import { DONATION_SECTION } from './store';
import PropTypes from 'prop-types';

LeaveMessage.propTypes = {
  show: PropTypes.bool
};

export function LeaveMessage(props) {
  const [show, setShow] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState(null);
  const uuid = React.useRef((Math.random() + 1).toString(36).substring(7));

  const onShow = (showLocal) => {
    // Show or Hide UI
    setValid(false);
    setMessage('');
    setShow(showLocal);

    if (showLocal) {
      // GA
      window.dataLayer.push({
        event: 'ConfirmLeaveAMessage',
        virtualPageURL: '/webpay/selectedleaveamessage/',
        virtualPageTitle: 'Dialog box for leave a message',
      });

      // Initialise
      window.userData.message = null;
      setErrorMsg(null);
    }
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onSkipHandler = () => {
    // GA tag
    window.dataLayer.push({
      event: 'SkippedLeaveAMessage',
      virtualPageURL: '/webpay/skippedleaveamessage/',
      virtualPageTitle: 'Skipped Leave a Message feature',
    });

    window.userData.message = null;
    showUINext({ nextSection: DONATION_SECTION.PAYMENT_WIDGET, backSection: DONATION_SECTION.LEAVE_MESSAGE });
  };
  const onSubmitLeaveMessage = () => {
    window.dataLayer.push({
      event: 'LeftAMessage',
      virtualPageURL: '/webpay/message/presseddonate/',
      virtualPageTitle: 'Donor left a Message',
    });


    window.userData.message = encodeURIComponent(message);
    showUI(DONATION_SECTION.PAYMENT_WIDGET, { backSection: DONATION_SECTION.LEAVE_MESSAGE });
  };

  const onSetMessage = (valueStr) => {
    setMessage(valueStr);
    if (valueStr.length === 0) {
      setErrorMsg('Please enter the message.');
      setValid(false);
    } else if (valueStr.length > 300) {
      setErrorMsg('Please do not enter more than 300 characters.');
      setValid(false);
    } else {
      setErrorMsg(null);
      setValid(true);
    }
  };

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="leave-message">
        <FadeInTransition show={show}>
          <div className="ch_heading_1 txt_primary">Your message</div>
          <div className="cstm_amount">
            <form className="form-group needs-validation" id={`leaveMessageForm${uuid.current}`}>
              <textarea
                className={`form-control ch_textarea_lg ${errorMsg ? 'activate_error' : ''}`}
                rows="5"
                name="message"
                id="leave-message-field"
                placeholder="Enter Message"
                value={message}
                onChange={(e) => onSetMessage(e.target.value)}
              />
              {errorMsg ? <div className="activate_error">{errorMsg}</div> : null}
            </form>
          </div>

          <div className="backNext-btn-div pt-5">
            <button
              className="btn ch_btn backNext-btn skip_btn"
              onClick={() => onSkipHandler()}
            >
              Skip
            </button>
            <button
              className="btn ch_btn backNext-btn next_btn"
              type="button"
              disabled={!valid}
              onClick={() => onSubmitLeaveMessage()}
            >
              Donate
            </button>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
