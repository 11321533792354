import React from 'react';
import { FadeInTransition } from './transition';
import { showUI_MessageInfo } from './message';
import { DONATION_SECTION } from './store';
import {
  getConfirmAmountBackSection, logError, LOG_ERROR_MSG, showUI, showUINext,
} from './common';
import PropTypes from 'prop-types';

AmountDonationValues.propTypes = {
  show: PropTypes.bool
};

export function AmountDonationValues(props) {
  const [show, setShow] = React.useState(false);

  const entityToChar = (str) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  };

  const onShow = (showLocal) => {
    // Configure backButton
    if (showLocal) {
      const payValues = window.charityDetail.payment_value.split(',');
      if (payValues === undefined || payValues.length === 0) {
        showUI_MessageInfo('Payment values not present.');
        logError(LOG_ERROR_MSG.PAYMENT_VALUES.format(window.userData.charity_name));
        setShow(false);
        return;
      }
    }

    // Show or Hide UI
    setShow(showLocal);
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onOtherAmount = () => {
    showUI(DONATION_SECTION.AMOUNT_DONATION_CUSTOM, { backSection: DONATION_SECTION.AMOUNT_DONATION_VALUES });
  };

  // click amount button
  const onSubmitAmountValue = (value) => {
    // set new amount value
    window.userData.amount = value;

    // send to confirmation page
    showUINext({ backSection: getConfirmAmountBackSection() });
  };

  const custom_amount = window.charityDetail?.custom_amount;
  let txt_payment_btn_screen = 'How much would you like to donate?';
  if (window.charityDetail && window.charityDetail.custom_text_field) txt_payment_btn_screen = window.charityDetail.custom_text_field.txt_payment_btn_screen;
  let payment_value = [];
  if (window.charityDetail) payment_value = window.charityDetail.payment_value.split(',');
  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="amount-donation-values">
        <FadeInTransition show={show}>
          <div className="ch_heading_1 txt_primary">{txt_payment_btn_screen}</div>
          <div className="amount">
            <div className="amount-row">
              {payment_value.map((item, idx) => (
                <button
                  key={idx}
                  className="btn ch_btn amount-btn"
                  onClick={() => onSubmitAmountValue(item)}
                >
                  {entityToChar(window.charityDetail.currency_symbol_html)}
                  {item}
                </button>
              ))}
            </div>
            <div className={custom_amount === 0 ? 'pt-3' : 'd-none'}>
              <button className="btn ch_btn category-btn" onClick={() => onOtherAmount()}>
                Other amount
              </button>
            </div>
          </div>

        </FadeInTransition>
      </div>
    </div>
  );
}
