import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  PaymentElement, useStripe, useElements, Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { showUI_MessageError, showUI_MessageSuccess } from './message';
import { setLoading, setContactDetails, store_webpay } from './store';
import {
  logError, LOG_ERROR_MSG, PAYMENT_PROCESSOR, showUINext,
} from './common';
import { events } from './events';
import PropTypes from 'prop-types';

import './stripe_widget.css';

const apiUrl = process.env.REACT_APP_BASE_URL;
const apiCharityCode = process.env.REACT_APP_CHARITY_CODE;

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
//   stripeAccount: 'acct_1M6vGXRAJXMH1mbO',
// });
// loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const saveTransaction = useCallback(async (clientSecret) => {
    try {
      // Remove any URL parameter and go back to initial state
      // https://stackoverflow.com/questions/22753052/remove-url-parameters-without-refreshing-page
      window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);

      // Retrieve Payment Intent
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      window.userData = JSON.parse(localStorage.getItem('user-data'));
      window.userData.transaction_code = paymentIntent.id;     

      window.charityDetail = JSON.parse(localStorage.getItem('charity-detail'));

      // Clean up
      localStorage.removeItem('user-data');
      localStorage.removeItem('charity-detail');

      // Store transaction
      const headers = { 'charity-code': apiCharityCode };
      const body = { data: { payment_intent_id: paymentIntent.id } };
      const response = await axios.post(`${apiUrl}v4/webpay/stripe/donation/`, body, { headers });
      const responseData = response.data.res;

      if (responseData.status === 'Failure') {
        // GA Tag
        window.dataLayer.push({
          event: 'failedsavingTransaction',
          virtualPageURL: '/webpay/dona/failedsavedtransaction/',
          virtualPageTitle: 'Dona Failed Saving the Transaction',
        });
        showUI_MessageError(responseData.msg);
        const msg = LOG_ERROR_MSG.TRANSACTION_PROCESSING.format(
          responseData.msg,
          window.charityDetail.charity_name,
          window.userData.transaction_code,
        );
        logError(msg, window.userData.transaction_code);
        return;
      }

      if (responseData.status !== 'Success') {
        // GA Tag
        window.dataLayer.push({
          event: 'failedNetwork',
          virtualPageURL: '/webpay/dona/failedNetwork/',
          virtualPageTitle: 'Dona Network Issue',
        });
        showUI_MessageError('Something went wrong');
        logError(
          LOG_ERROR_MSG.NETWORK_ISSUE.format(window.charityDetail.charity_name, window.userData.transaction_code),
          `${window.userData.transaction_code} ${JSON.stringify(responseData)}`,
        );
        return;
      }

      // Set Loading
      setLoading(false);

      // When category is giftAided
      // NOTE: We need to rely on responseData for Stripe
      if (window.charityDetail.giftaid_form_flag === 1 && responseData.data.is_giftaided === 1) {
        // Donor not registered -> proceed to giftaid
        if (responseData.data.donor === null || responseData.data.donor.status === 'CONTACT_REGISTERED') {
          window.userData.transaction_code = responseData.data.transaction_code;
          showUINext();
          return;
        }

        // Donor registered
        if (responseData.data.donor !== null) {
          window.dataLayer.push({
            event: 'DonorRegistered',
            virtualPageURL: '/webpay/donorregistered/',
            virtualPageTitle: 'User was Registered',
          });
          window.dataLayer.push({
            event: 'savedNormalTransaction',
            virtualPageURL: '/webpay/savednormaltrasaction/',
            virtualPageTitle: 'Save Normal Transaction',
          });
          showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_giftaid);
          return;
        }
      }

      // When category is not giftAided and Donor not registered - collect donor details
      if (window.charityDetail.collect_donor_details_flag 
          && window.userData.category.is_giftaided === 0 
          && responseData.data.donor === null) {
        setContactDetails(true);
        showUINext();
        return;
      }

 
      // GA Tag
      window.dataLayer.push({
        event: 'savedNormalTransaction',
        virtualPageURL: '/webpay/savednormaltrasaction/',
        virtualPageTitle: 'Save Normal Transaction',
      });

      // When giftaid disabled and category selected is_giftaided and donor is identified
      if (window.charityDetail.giftaid_form_flag === 0
          && window.userData.category.is_giftaided === 1
          && responseData.data.donor !== null) {
        showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_giftaid);
        return;
      }

      // show thankyou normal donation message;
      showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_donation);

      // Show success message
      // showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_giftaid);
    } catch (e) {
      console.log(e);
      showUI_MessageError('Due to network issue we were unable to process your request! Please send us an email at contact@mydona.com');
      logError(
        LOG_ERROR_MSG.NETWORK_ISSUE.format(window.charityDetail.charity_name, window.userData.transaction_code),
        window.userData.transaction_code,
      );
    }
  }, [stripe]);

  useEffect(() => {
    if (!stripe) return;

    // Check for client secret
    const url_search_params = new URLSearchParams(window.location.search);
    const clientSecret = url_search_params.get('payment_intent_client_secret');
    if (clientSecret != null) {
      // Trying to retrieve payment intent
      saveTransaction(clientSecret);
    }
  }, [saveTransaction, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Persist the current redux store before redirect
    store_webpay.persistStoreBeforeReload();

    // https://docs.stripe.com/stripe-js/react
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if( result.error ){
      if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
        setMessage(result.error.message);
      } else {        
        setMessage('An unexpected error occurred.');
      }
    }else{
      saveTransaction(result.paymentIntent.client_secret);
    }


    setIsLoading(false);
  };

  const onPaymentElementReady = () => {
    // Event used to notify sumup is ready
    setTimeout(() => {
      // console.log('Triggering stripeWidget:ready');
      events.trigger('stripeWidget:ready');
    }, 2000);
    setLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onReady={() => onPaymentElementReady()}
        onClick={() => console.log('elements is clicked')}
      />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner" /> : 'DONATE'}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

StripeWidget.propTypes = {
  show: PropTypes.bool
};

export function StripeWidget(props) {
  const [show, setShow] = React.useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(null);

  const createPaymentIntent = async () => {
    // Create PaymentIntent as soon as the page loads
    const headers = { 'charity-code': apiCharityCode };
    const body = {
      data: {
        charity_id: window.charityDetail.charity_id,
        amount: parseFloat(window.userData.amount),
        device_name: window.charityDetail.device_name,
        donation_category: unescape(window.userData.category.name),
        is_giftaided: window.userData.category.is_giftaided,
        message: window.userData.message,
      },
    };

    // Store data prior to reloading
    // Stripe will reload the page on submission, so this stops the app state
    // from being lost.
    localStorage.setItem('user-data', JSON.stringify(window.userData));
    localStorage.setItem('charity-detail', JSON.stringify(window.charityDetail));

    const response = await axios.post(`${apiUrl}v4/webpay/stripe/paymentintent/`, body, { headers });
    setClientSecret(response.data.res.data.client_secret);
  };

  const onShow = async (showLocal) => {
    // Configure backButton
    if (showLocal) {
      // GA Tag
      // TODO: Change this event as we're using the StripeWidget
      window.dataLayer.push({
        event: 'SumupWidgetLoaded',
        virtualPageURL: '/webpay/sumup/widgetloaded',
        virtualPageTitle: 'Sumup Widget Loaded.',
      });

      createPaymentIntent();
    }

    // Show or Hide UI
    setShow(showLocal);
  };

  const onRedirect = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const clientSecret = urlParams.get('payment_intent_client_secret');
    if (clientSecret !== null) {
      setClientSecret(clientSecret);
    }
  };

  React.useEffect(() => {
    events.on('stripeWidget:redirect', onRedirect);

    return () => {
      events.off('stripeWidget:redirect', onRedirect);
    };
  });

  const loadStripeLaunch = async () => {
    // if( window.charityDetail
    //     && window.charityDetail.stripe_account_id
    //     && window.charityDetail.payment_processor === PAYMENT_PROCESSOR.STRIPE){
    //   // const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    //   //   stripeAccount: window.charityDetail.stripe_account_id,
    //   // });
    //   const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    //   // NOTE: This is required to make the stripePromise to be reloaded
    //   setStripePromise(stripePromise)
    // }else{
    //   setStripePromise(null)
    // }


    // DONA-1414: Stripe calls should not exist if in sumup
    if (window.charityDetail
        && window.charityDetail.payment_processor === PAYMENT_PROCESSOR.STRIPE) {
      const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      // NOTE: This is required to make the stripePromise to be reloaded
      setStripePromise(stripePromise);
    } else {
      setStripePromise(null);
    }
  };

  
  React.useEffect(() => {
    // Required to reset Stripe
    const reset = () => {
      setClientSecret(null);
    };

    events.on('reset', reset);

    return () => {
      events.off('reset', reset);
    };
  }, []);

  React.useEffect(() => {
    loadStripeLaunch();
    // eslint-disable-next-line
  }, [window.charityDetail]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="stripe-widget widget__cntr">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
}
