import './App.css';
import React from 'react';
import TagManager from 'react-gtm-module';
import axios from 'axios';
import Donation from './components/donation';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/donation.css';
import './css/gift-aid.css';
import './css/icons.css';
import './css/style.css';
import './css/toastr.css';
import './css/widget.css';
 
const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

axios.interceptors.request.use((config) => {
  config.headers['dona-code'] = process.env.REACT_APP_DONA_CODE;
  return config;
}, (error) => Promise.reject(error));

function App() {
  return <Donation />;
}

export default App;
