import axios from 'axios';
import {
  setHeader, setLoading, setSection, store_webpay, DONATION_SECTION, setContactDetails,
} from './store';
import { events } from './events';
import { GIFTAID_SECTION } from './giftaid';
import { showUI_MessageInfo, showUI_MessageError } from './message';

const apiUrl = process.env.REACT_APP_BASE_URL;
const apiCharityCode = process.env.REACT_APP_CHARITY_CODE;

// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
  let i = 0; const
    args = arguments;
  return this.replace(/{}/g, () => (typeof args[i] !== 'undefined' ? args[i++] : ''));
};

export const PAYMENT_PROCESSOR = { STRIPE: 'stripe', SUMUP: 'sumup' };

const TITLE_GIFT_AID_DECLARATION = 'Gift Aid Declaration';
const TITLE_CONTACT_DETAILS_FLOW = 'Contact Details';

// Holds the instance to fill up the card
window.sumupCardInstance = null;
window.backSectionAmountCustom = null;

const initialiseUserData = () => ({
  category: {
    name: null,
    is_giftaided: null,
  },
  amount: null,
  message: null,
  donor: {
    firstName: null,
    lastName: null,
    email: null,
    address: null,
    postcode: null,
    email_opt_in: '0',
  },
  transaction: {}, // Stores information from sumup
  transaction_code: null,
  checkout_id: null,
});

export const getConfirmAmountBackSection = () => {
  // Determine back section
  let backSection = null;
  if (window.charityDetail.tab_initial_payment_flag === 1) backSection = DONATION_SECTION.AMOUNT_DONATION_CUSTOM;
  else backSection = DONATION_SECTION.AMOUNT_DONATION_VALUES;
  return backSection;
};

// Restore initial configuration
export const restoreConfiguration = () => {
  // Reprogram charityDetail
  window.charityDetail = { ...window.charityDetailSnapshot };
};

// click action of click button reset to default screen
export const resetUI = () => {
  // Reset to initial values to prevent more state changes
  setSection({
    section: null, backSection: null, giftAidSection: null, loading: false,
  });
  setContactDetails(false);
  // GA code
  window.dataLayer.push({
    event: 'CancelButton',
    virtualPageURL: '/cancelledbtn/tryagain',
    virtualPageTitle: 'Cancel Button Pressed',
  });

  // Initialise UserData
  initialiseUserData();

  // Trigger reset to all components
  events.trigger('reset');

  // getCharityData
  const pathArray = window.location.pathname.split('/');
  const web_payment_url = pathArray[1];
  getCharityData(null, web_payment_url);

  // unmount SumUp card
  try {
    if (window.sumupCardInstance) {
      window.sumupCardInstance.unmount();
      window.userData.checkout_id = null;
    }
  } catch (e) {
    console.log('window.sumupCardInstance failed to unmount');
  }
};

export const redirectToWebPaymentUrl = async (charityId) => {
  setLoading(true);
  try {
    const headers = { 'charity-code': apiCharityCode };
    const params = `charity_id=${charityId}`;
    const response = await axios.get(`${apiUrl}v4/webpay/charity/?${params}`, { headers });
    const responseData = response.data.res;

    // Redirect to web-payment-url
    const getUrl = window.location;
    const baseUrl = `${getUrl.protocol}//${getUrl.host}/${getUrl.pathname.split('/')[1]}`;
    window.location = `${baseUrl}${responseData.data.web_payment_url}`;
  } catch (e) {
    console.log('e ', e.response.data);
    const msg = e.response.data ? `Response: '${e.response.data.res.msg}'` : '';
    showUI_MessageError(`Something went wrong. ${msg}`);
  }
};

// get charity detail
export const getCharityData = async (charityId, web_payment_url, web_payment_url_only = false) => {
  // Initialise previous load
  window.userData = initialiseUserData();
  setLoading(true);
  try {
    const headers = { 'charity-code': apiCharityCode };

    // Retrieve charity data
    const dataVal = {
      charity_code: apiCharityCode,
      web_payment_url,
      charity_id: null,
    };
    // Append
    if (web_payment_url_only === 0) dataVal.charity_id = charityId;
    let params = `web_payment_url=${web_payment_url}`;
    if (web_payment_url_only === 0) params = `charity_id=${charityId}`;
    // console.log("----------------------")
    // console.log(`GetCharityData: ${apiUrl}v4/webpay/charity/?${params}`)
    // console.log("----------------------")
    const response = await axios.get(`${apiUrl}v4/webpay/charity/?${params}`, { headers });
    const responseData = response.data.res;

    // Check whether reloadRequired
    const timestamp_server = response.headers.deployment;
    const timestamp = localStorage.getItem('deploymentTimestamp');
    const reloadRequired = timestamp < timestamp_server;
    if (reloadRequired) {
      localStorage.setItem('deploymentTimestamp', timestamp_server);

      // Give a bit of time before event is raised [ USED BY CYPRESS ]
      await new Promise((resolve) => setTimeout(resolve, 500));
      events.trigger('getCharityData:reloadRequired');

      // Launch reload
      await new Promise((resolve) => setTimeout(resolve, 5000));
      window.location.reload();
      return;
    }

    // Store charityData
    window.charityDetail = { ...responseData.data };
    window.charityDetailSnapshot = { ...responseData.data };
    if (window.DEV) console.log('charityDetail ', window.charityDetail);
    window.primarySelectedDetails = responseData.data;

    // // ---------------------
    // showUI(DONATION_SECTION.TERMS_CONDITIONS)
    // return
    // // ---------------------

    // // ------------------
    // // THIS IS HACKING TO DEBUG GIFTAID
    // showUI(DONATION_SECTION.GIFT_AID_CONFIRM);
    // return;
    // // ------------------

    // Load SumUp SDK
    const paymentProcessor = window.charityDetail.payment_processor;
    if (paymentProcessor == null || paymentProcessor === PAYMENT_PROCESSOR.SUMUP) {
      let sumUpSdkScriptElement = window.document.createElement('script');
      sumUpSdkScriptElement.setAttribute('src', 'https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js');
      window.document.body.appendChild(sumUpSdkScriptElement);

      // Check and save SDK load status
      window.sumUpSdkIsLoaded = false;
      window.sumUpSdkScriptElement = sumUpSdkScriptElement; 
      sumUpSdkScriptElement.addEventListener('load', () => {
        window.sumUpSdkIsLoaded = true;
      });
    }

    // check web payment is enable for charity or NOT
    if (window.charityDetail.licence_flag === 1) { // 1 => disable (licence flag is disable)
      showUI_MessageInfo('License Expired');
      logError(LOG_ERROR_MSG.LICENCE_EXPIRED.format(window.charityDetail.charity_name));
      return false;
    }
    if (window.charityDetail.charity_webpayment_flag !== 1) { // 1 => enable
      showUI_MessageInfo('Web payment is disabled. Please contact admin.');
      logError(LOG_ERROR_MSG.WEBPAYMENT_DISABLED.format(window.charityDetail.charity_name));
      return false;
    }

    // Check if we are in a redirect from donation
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const clientSecret = urlParams.get('payment_intent_client_secret');
    if (clientSecret !== null) {
      events.trigger('stripeWidget:redirect');
      return;
    }

    // Render categories
    showUI(DONATION_SECTION.CATEGORIES);
  } catch (e) {
    console.log('e ', JSON.stringify(e));
    const msg = e.response.data ? `Response: '${e.response.data.res.msg}'` : '';
    showUI_MessageError(`Something went wrong. ${msg}`);
  }
};

/**
 * @typedef {ShowUiOptions} ShowUiNextOptions
 */

/**
 * @param {ShowUiNextOptions} options 
 */
export const showUINext = (options) => {
  const { section } = store_webpay.getState();
  // Display userData
  // console.log("showUINext ", section)
  if (section === DONATION_SECTION.CATEGORIES) {    
    if (window.charityDetail.tab_initial_payment_flag === 1) showUI(DONATION_SECTION.AMOUNT_DONATION_CUSTOM, options);
    else showUI(DONATION_SECTION.AMOUNT_DONATION_VALUES, options);
    return;
  }

  if (section === DONATION_SECTION.AMOUNT_DONATION_VALUES) {
    if (window.charityDetail.confirmation_flag === 1) {
      showUI(DONATION_SECTION.CONFIRM_AMOUNT);
    } else if (window.charityDetail.message_flag === 1) {
      showUI(DONATION_SECTION.CONFIRM_LEAVE_MESSAGE, options);
    } else {
      showUI(DONATION_SECTION.PAYMENT_WIDGET, options);
    }
    return;
  }

  if (section === DONATION_SECTION.AMOUNT_DONATION_CUSTOM) {
    if (window.charityDetail.confirmation_flag === 1) {
      showUI(DONATION_SECTION.CONFIRM_AMOUNT, options);
    } else if (window.charityDetail.message_flag === 1) {
      showUI(DONATION_SECTION.CONFIRM_LEAVE_MESSAGE, options);
    } else {
      showUI(DONATION_SECTION.PAYMENT_WIDGET, options);
    }
    return;
  }

  if (section === DONATION_SECTION.CONFIRM_AMOUNT) {
    if (window.charityDetail.message_flag === 1) {
      showUI(DONATION_SECTION.CONFIRM_LEAVE_MESSAGE, options);
    } else {
      showUI(DONATION_SECTION.PAYMENT_WIDGET, { backSection: getConfirmAmountBackSection(), loading: true });
    }
    return;
  }

  if (section === DONATION_SECTION.CONFIRM_LEAVE_MESSAGE) {
    // Activate loading when going to sumup
    if (options.nextSection === DONATION_SECTION.PAYMENT_WIDGET) options.loading = true;

    showUI(options.nextSection, options);
    return;
  }

  if (section === DONATION_SECTION.LEAVE_MESSAGE) {
    showUI(DONATION_SECTION.PAYMENT_WIDGET, { loading: true });
    return;
  }

  if (section === DONATION_SECTION.PAYMENT_WIDGET) {
    showUI(DONATION_SECTION.GIFT_AID_CONFIRM);
    return;
  }

  if (section === DONATION_SECTION.GIFT_AID_CONFIRM) {
    showUI(DONATION_SECTION.GIFT_AID, { giftAidSection: GIFTAID_SECTION.FIRSTNAME });
    return;
  }
  if (section === DONATION_SECTION.GIFT_AID) {
    if (window.charityDetail.tab_collect_email_device_flag === 0) {
      window.userData.donor.subscribe = '1';
      showUI(DONATION_SECTION.TERMS_CONDITIONS);
    } else {
      showUI(DONATION_SECTION.SUBSCRIBE);
    }
    return;
  }
  if (section === DONATION_SECTION.SUBSCRIBE) {
    showUI(DONATION_SECTION.TERMS_CONDITIONS);
  }
  // if( section === DONATION_SECTION.TERMS_CONDITIONS )
};

/**
 * @typedef {Object} ShowUiOptions
 * @property {import('./store').DonationSection | null} [backSection]
 * @property {import('../components/giftaid').GiftAidSection | null} [giftAidSection]
 * @property {Boolean | null} [loading]
 */

/**
 * @param {import('./store').DonationSection} section
 * @param {ShowUiOptions} [options] 
 * @returns 
 */
export const showUI = (section, options = {}) => {
  // Display userData
  // console.log("showUI ", section, options, userData)

  // Store section
  const backSection = options.backSection ? options.backSection : null;
  const giftAidSection = options.giftAidSection ? options.giftAidSection : null;
  const loading = options.loading != null ? options.loading : false;
  setSection({
    section, backSection, giftAidSection, loading,
  });

  const { contactDetailsFlow } = store_webpay.getState();
  let title = '';
  // message on screen;
  if (!window.charityDetail.custom_text_field.txt_dcp_name.includes('{{organisation_name}}')) {
    title = window.charityDetail.custom_text_field.txt_dcp_name;
  } else {
    title = window.charityDetail.charity_name;
  }

  if (section === DONATION_SECTION.CATEGORIES) {
    setHeader({ title, backSection, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.AMOUNT_DONATION_VALUES) {
    setHeader({ title, backSection, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.AMOUNT_DONATION_CUSTOM) {
    // Configure back button
    window.backSectionAmountCustom = backSection;
    setHeader({ title, backSection, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.CONFIRM_AMOUNT) {
    setHeader({ title, backSection: false, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.CONFIRM_LEAVE_MESSAGE) {
    let backSectionLocal = DONATION_SECTION.CONFIRM_AMOUNT;
    // if payment confirmation flag is disabled then redirect to proper amount selection value
    if (window.charityDetail.confirmation_flag === 0) {
      if (window.charityDetail.tab_initial_payment_flag === 1) backSectionLocal = DONATION_SECTION.AMOUNT_DONATION_CUSTOM;
      else backSectionLocal = DONATION_SECTION.AMOUNT_DONATION_VALUES;
    }
    setHeader({ title, backSection: backSectionLocal, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.LEAVE_MESSAGE) {
    const backSection = DONATION_SECTION.CONFIRM_LEAVE_MESSAGE;
    setHeader({ title, backSection, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.PAYMENT_WIDGET) {
    setHeader({ title, backSection, showCancel: false });
    return;
  }

  if (section === DONATION_SECTION.GIFT_AID_CONFIRM) {
    setHeader({ title: '', backSection: null, showCancel: false });
    return;
  }
  if (section === DONATION_SECTION.GIFT_AID) {
    setHeader({ title: contactDetailsFlow ? TITLE_CONTACT_DETAILS_FLOW : TITLE_GIFT_AID_DECLARATION, backSection: null, showCancel: true });
    return;
  }
  if (section === DONATION_SECTION.SUBSCRIBE) {
    const backSection = DONATION_SECTION.GIFT_AID;
    setHeader({ title: contactDetailsFlow ? TITLE_CONTACT_DETAILS_FLOW : TITLE_GIFT_AID_DECLARATION, backSection, showCancel: false });
    return;
  }
  if (section === DONATION_SECTION.TERMS_CONDITIONS) {
    const backSectionLocal = DONATION_SECTION.TERMS_CONDITIONS;
    setHeader({ title: contactDetailsFlow ? TITLE_CONTACT_DETAILS_FLOW : TITLE_GIFT_AID_DECLARATION, backSection: backSectionLocal, showCancel: false });
  }
};

// log Error details
export const LOG_ERROR_MSG = /** @type {const} */ ({
  PAYMENT_VALUES: 'Payment values not present for {}.',
  LICENCE_EXPIRED: 'Licence Expired for the Charity-{}.',
  WEBPAYMENT_DISABLED: 'Web payment is disabled for {}.',
  CHECKOUT: 'Error creating checkout, or the merchant account for {} is not registered with us.',

  DONATION_PROCESSING: 'There was an error processing the donation of {}. ',
  DONATION_PROCESSING_TRANSACTION_CODE: `There was an error processing the donation of {}. 
        Refer to the transaction code {}.`,
  DONATION_PROCESSING_SUMUP: 'While processing the donation over SumUp we encountered the following error: {}, for the charity - {}.',

  TRANSACTION_PROCESSING: `While processing the transaction we encountered the following error: {}, for the {}. 
        Refer to the transaction code for more detail {}`,
  TRANSACTION_PROCESSING_DONOR_ID: `While processing the transaction we encountered the following error: {}, for the {}. 
        Refer to the transaction code {} and donor id {} for more details.`,

  NETWORK_ISSUE: `Due to network issue, we are unable to track the transaction for the {}. 
        Refer to the transaction code for more details {}.`,
  NETWORK_ISSUE_DONOR_ID: `Due to network issue, we were unable to track the gift-aid details 
        for the {}. Refer to the transaction code {} and donor id {} for more details.`,
  NETWORK_ISSUE_DONOR_EMAIL: `Due to network issue, we were unable to track the gift-aid details 
        for the {}. Refer the transaction code {} and donor email {} for more details.`,

  GIFTAID_TRACKING: `While gift-aid tracking we encounter the following error: {}, for the {}. 
        Refer to the transaction code {} and donor email {} for more details.`,

});

export const logError = (message, transactionCode) => {
  const headers = { 'charity-code': apiCharityCode };
  const reqData = {
    charity_id: window.charityDetail.charity_id,
    charity_name: window.charityDetail.charity_name,
    transaction_code: transactionCode,
    mail_flag: '0',
    message,
    relation: 'Payment',
    operation: 'WebPaymentError',
  };
  try {
    axios.post(`${apiUrl}v4/webpay/activitylog/`, { data: reqData }, { headers });
  } catch (e) {
    console.log('e ', e);
    console.log('e ', e.response.data);
  }
};
