import React from 'react';
import { store_webpay, DONATION_SECTION } from './store';
import { showUI, resetUI, restoreConfiguration } from './common';
import { GIFTAID_SECTION } from './giftaid';
// import { restoreConfiguration } from './categories'
import { events } from './events';

export function Header() {
  const unsubscribeRef = React.useRef(null);
  const [data, setData] = React.useState({
    title: '',
    backSection: '',
    showCancel: false,
  });

  React.useEffect(() => {
    // Subscribe to current changes
    if (unsubscribeRef.current) unsubscribeRef.current();
    unsubscribeRef.current = store_webpay.subscribe((state, previousState) => {
      if (state.header !== previousState.header) {
        setData({ ...state.header });
      }
    });
    return () => {
      unsubscribeRef.current();
    };
  });

  // back button redirect section
  const onClickBack = () => {
    // Destroy sumupCard Instance
    if (window.sumupCardInstance) {
      window.userData.checkout_id = null;
      window.sumupCardInstance.unmount();
      window.sumupCardInstance = null;
    }

    // Restore configuration
    if (data.backSection === DONATION_SECTION.CATEGORIES) {
      restoreConfiguration();
    }

    if (data.backSection === DONATION_SECTION.AMOUNT_DONATION_VALUES) {
      const backSectionLocal = window.charityDetail.donation_category_flag === 1 ? DONATION_SECTION.CATEGORIES : null;
      showUI(data.backSection, { backSection: backSectionLocal });
      return;
    }

    if (data.backSection === DONATION_SECTION.AMOUNT_DONATION_CUSTOM) {
      showUI(data.backSection, { backSection: window.backSectionAmountCustom });
      return;
    }

    if (data.backSection === DONATION_SECTION.GIFT_AID) {
      // show email back
      showUI(data.backSection, { giftAidSection: GIFTAID_SECTION.EMAIL });
      return;
    }

    if (data.backSection === DONATION_SECTION.TERMS_CONDITIONS) {
      // setTermsConditionPage( termsScroll.currentPage - 1 )
      const { page } = store_webpay.getState();
      if (page === 0) {
        if (window.charityDetail.tab_collect_email_device_flag === 1) showUI(DONATION_SECTION.SUBSCRIBE);
        else showUI(DONATION_SECTION.GIFT_AID, { giftAidSection: GIFTAID_SECTION.EMAIL });
      } else {
        events.trigger('termsConditions:set', { page: page - 1 });
      }
      return;
    }

    // Generic backSection button
    showUI(data.backSection);
  };

  const showBack = data.backSection !== null && data.backSection.length > 0;
  return (
    <div className="screen_header bg_primary">
      <div className="screen_header_back">
        {showBack
          ? (
            <i onClick={() => onClickBack()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path fill="#ffffff" d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
            </i>
          )
          : null}
        {data.showCancel
          ? (
            <i onClick={() => resetUI()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </i>
          )
          : null}
      </div>
      <h4>{data.title}</h4>
      <div className="scrren_header_env text-capitalize" />
    </div>
  );
}
