import React from 'react';
import { FadeInTransition } from './transition';
import { resetUI, showUINext, getConfirmAmountBackSection } from './common';
import PropTypes from 'prop-types';

ConfirmAmountDonation.propTypes = {
  show: PropTypes.bool
};


export function ConfirmAmountDonation(props) {
  const [show, setShow] = React.useState(false);

  const onShow = (showLocal) => {
    setShow(showLocal);
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const entityToChar = (str) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  };

  const onConfirmHandler = () => {
    window.dataLayer.push({
      event: 'AmountConfirmationScreen',
      virtualPageURL: '/webpay/donationamountconfirmed/',
      virtualPageTitle: 'Donation Confirmation screen',
    });
    showUINext({ backSection: getConfirmAmountBackSection() });
  };

  let txt_confirmation_screen = '';
  let charity_name = '';
  let currency_symbol_html = '';
  if (window.charityDetail) {
    if (window.charityDetail.custom_text_field && window.charityDetail.custom_text_field.txt_confirmation_screen) { txt_confirmation_screen = window.charityDetail.custom_text_field.txt_confirmation_screen; }
    if (window.charityDetail.charity_name) { charity_name = window.charityDetail.charity_name; }
    if (window.charityDetail.currency_symbol_html) currency_symbol_html = window.charityDetail.currency_symbol_html;
  }
  let amount = '';
  if (window.userData) amount = window.userData.amount;
  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="confirm-amount-donation">
        <FadeInTransition show={show}>
          <div className="txt_primary ch_heading_1">{txt_confirmation_screen}</div>
          <div className="confirm_amount">
            <div className="display_amt">
              {entityToChar(currency_symbol_html)}
              {amount}
            </div>
            <h2>to</h2>
            <h2>
              {charity_name}
              ?
            </h2>
            <hr />
          </div>

          <div className="customAmt-btn-div pt-3">
            <button
              className="btn ch_btn category-btn next_btn"
              onClick={() => onConfirmHandler()}
            >
              Confirm
            </button>
          </div>
          <div className="customAmt-btn-div">
            <button
              className="btn ch_btn category-btn cancel_btn"
              onClick={() => resetUI()}
            >
              Cancel
            </button>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}

