import React from 'react';
import { store_webpay, DONATION_SECTION } from './store';
import { getCharityData, redirectToWebPaymentUrl, PAYMENT_PROCESSOR } from './common';

// Components
import { Header } from './header';
import { Categories } from './categories';
import { AmountDonationCustom } from './amount_donation_custom';
import { AmountDonationValues } from './amount_donation_values';
import { ConfirmAmountDonation } from './confirm_amount_donation';
import { ConfirmLeaveMessage } from './confirm_leave_message';
import { LeaveMessage } from './leave_message';
import { Subscribe } from './subscribe';
import { ConfirmGiftAid } from './confirm_giftaid';
import { TermsConditions } from './terms_conditions';
import { SumupWidget } from './sumup_widget';
import { StripeWidget } from './stripe_widget';
import { GiftAid } from './giftaid';
import { Message } from './message';

function Donation() {
  const unsubscribeRef = React.useRef(null);
  const [section, setSection] = React.useState({ current: null, loading: false });
  const giftAidSection = React.useRef(null);

  React.useEffect(() => {
    // Subscribe to current changes
    if (unsubscribeRef.current) unsubscribeRef.current();
    unsubscribeRef.current = store_webpay.subscribe((state, previousState) => {
      if (state.section !== previousState.section) {
        giftAidSection.current = state.giftAidSection;
        setSection({ current: state.section, loading: state.loading });
      } else if (state.loading !== previousState.loading) {
        setSection({ ...section, loading: state.loading });
      }
    });
    return () => {
      unsubscribeRef.current();
    };
  });

  React.useEffect(() => {
    // Check whether cid in URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cid = urlParams.get('cid');
    if (cid !== null) {
      // redirect to webpaymentUrl
      redirectToWebPaymentUrl(cid, null);
      return;
    }

    const webpaymenturl = urlParams.get('webpaymenturl');
    if (webpaymenturl !== null) {
      getCharityData(null, webpaymenturl);
      return;
    }

    // Path Param
    const pathArray = window.location.pathname.split('/');
    const web_payment_url = pathArray[1];
    getCharityData(null, web_payment_url);
  }, []);

  const payment_processor = window.charityDetail && window.charityDetail.payment_processor != null
    ? window.charityDetail.payment_processor : PAYMENT_PROCESSOR.SUMUP;
  const showMessage = [DONATION_SECTION.MESSAGE_INFO,
    DONATION_SECTION.MESSAGE_SUCCESS,
    DONATION_SECTION.MESSAGE_ERROR].includes(section.current);
  return (
    <div className="screen">
      <Header />
      <div className={`spinnerDiv ${section.loading ? '' : 'd-none'}`}>
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>

      <Message show={showMessage} />
      <Categories show={section.current === DONATION_SECTION.CATEGORIES} />
      <AmountDonationValues show={section.current === DONATION_SECTION.AMOUNT_DONATION_VALUES} />
      <AmountDonationCustom show={section.current === DONATION_SECTION.AMOUNT_DONATION_CUSTOM} />
      <ConfirmAmountDonation show={section.current === DONATION_SECTION.CONFIRM_AMOUNT} />
      <ConfirmLeaveMessage show={section.current === DONATION_SECTION.CONFIRM_LEAVE_MESSAGE} />
      <LeaveMessage show={section.current === DONATION_SECTION.LEAVE_MESSAGE} />
      <SumupWidget show={section.current === DONATION_SECTION.PAYMENT_WIDGET
                && payment_processor === PAYMENT_PROCESSOR.SUMUP}
      />
      <StripeWidget show={section.current === DONATION_SECTION.PAYMENT_WIDGET
                && payment_processor === PAYMENT_PROCESSOR.STRIPE}
      />

      <ConfirmGiftAid show={section.current === DONATION_SECTION.GIFT_AID_CONFIRM} />
      <GiftAid show={section.current === DONATION_SECTION.GIFT_AID} section={giftAidSection.current} />
      <Subscribe show={section.current === DONATION_SECTION.SUBSCRIBE} />
      <TermsConditions show={section.current === DONATION_SECTION.TERMS_CONDITIONS} />
    </div>
  );
}
export default Donation;
