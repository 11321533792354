import React from 'react';
import { FadeInTransition } from './transition';
import { showUINext } from './common';
import { DONATION_SECTION } from './store';
import PropTypes from 'prop-types';

ConfirmLeaveMessage.propTypes = {
  show: PropTypes.bool
};

export function ConfirmLeaveMessage(props) {
  const [show, setShow] = React.useState(false);

  const onShow = (showLocal) => {
    setShow(showLocal);
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onNoHandler = () => {
    // GA tag
    window.dataLayer.push({
      event: 'SkippedLeaveAMessage',
      virtualPageURL: '/webpay/skippedleaveamessage/',
      virtualPageTitle: 'Skipped Leave a Message feature',
    });
    showUINext({ nextSection: DONATION_SECTION.PAYMENT_WIDGET, backSection: DONATION_SECTION.CONFIRM_LEAVE_MESSAGE });
  };
  const onYesHandler = () => {
    showUINext({ nextSection: DONATION_SECTION.LEAVE_MESSAGE });
  };

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="confirm-leave-message">
        <FadeInTransition show={show}>
          <div className="confirm_amount leave_message">
            <div className="ch_heading_1_r mt-3 mb-3">
              Do you want to leave a message with your donation?
            </div>
            <div className="backNext-btn-div pt-4">
              <button
                className="btn ch_btn backNext-btn"
                onClick={() => onNoHandler()}
              >
                {' '}
                No
              </button>
              <button
                className="btn ch_btn backNext-btn"
                onClick={() => onYesHandler()}
              >
                {' '}
                Yes
              </button>
            </div>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
