import React from 'react';
import PropTypes from 'prop-types';

export function useInterval({
  callback, completed, delay, progress, progress_step, progress_max,
}) {
  const savedCallback = React.useRef();
  const savedCompleted = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
    savedCompleted.current = completed;
  }, [callback, completed]);
 
  // Set up the interval.
  React.useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current(), delay);
      return () => clearInterval(id);
    }
  }, [delay]);

  // Set up the interval.
  React.useEffect(() => {
    if (savedCompleted.current == null) return;

    if (progress + progress_step === progress_max) {
      savedCompleted.current();
    }
  }, [progress, progress_step, progress_max]);
}

FadeInTransition.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
};

export function FadeInTransition(props) {
  const PROGRESS_MAX = 100;
  const PROGRESS_MIN = 0;
  const PROGRESS_STEP = 2;
  const PROGRESS_DELAY = 10;
  const [progress, setProgress] = React.useState(PROGRESS_MAX);

  const showRef = React.useRef(false); // Stores the last state of props.show to get the transition false -> true

  // launch transition
  const delay = progress <= PROGRESS_MAX ? PROGRESS_DELAY : null;
  useInterval({ callback: () => setProgress(progress + PROGRESS_STEP), delay });

  React.useEffect(() => {
    if (props.show === true) { setProgress(PROGRESS_MIN); } else setProgress(PROGRESS_MAX);
    showRef.current = props.show;
  }, [props.show]);
  let opacity = progress / 100;
  // When transition false -> true, still progress = 100 but props.show = true
  if (props.show === true && showRef.current === false) opacity = 0;
  return (
    <div style={{ opacity }}>
      {props.children}
    </div>
  );
}
