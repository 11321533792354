import React from 'react';
import { FadeInTransition } from './transition';
import { showUINext, resetUI, getConfirmAmountBackSection } from './common';
import PropTypes from 'prop-types';
import { logError } from './common';


AmountDonationCustom.propTypes = {
  show: PropTypes.bool
};

// send to custom amount screen
export function AmountDonationCustom(props) {
  const [show, setShow] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState(null);
  const uuid = React.useRef((Math.random() + 1).toString(36).substring(7));

  const onShow = (showLocal) => {
    // Configure backButton
    if (showLocal) {
      // GA tag
      window.dataLayer.push({
        event: 'CustomAmountScreen',
        virtualPageURL: '/webpay/selectedcustomamount/',
        virtualPageTitle: 'Entered Custom Amount Screen',
      });
      setAmount('');
      setErrorMsg(null);
    }

    // Show or Hide UI
    setShow(showLocal);
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onCustomAmount = (value) => {
    // Set new value
    setAmount(value);
    window.userData.amount = value;
    // Check if error
    if (value.length === 0) {
      setErrorMsg('Please enter amount');
    } else if ((value > 0) === false) {
      setErrorMsg(`Minimum Amount should be ${entityToChar(window.charityDetail.currency_symbol_html)} 1`);
    } else if (value.match(/^\d{1,4}(\.\d{1,2})?$/) == null) {
      setErrorMsg('Max upto 4 digits and 2 decimal values are allowed.');
    } else {
      setErrorMsg(null);
    }
  };
  const onSubmitCustomAmount = () => {
    // GA Tag
    window.dataLayer.push({
      event: 'UserFilledCustomAmount',
      virtualPageURL: '/webpay/donatedcustomamount/',
      virtualPageTitle: 'User Filled the Custom Amount.',
    });

    // window.userData.amount = amount;
    if( window.userData.amount == null)
      logError(`Amount is null charityDetail=${JSON.stringify(window.charityDetail)} userData=${JSON.stringify(window.userData)}`, '');
    showUINext({ backSection: getConfirmAmountBackSection() });
  };

  const entityToChar = (str) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  };

  let txt_payment_btn_screen = 'How much would you like to donate?';
  if (window.charityDetail && window.charityDetail.custom_text_field) txt_payment_btn_screen = window.charityDetail.custom_text_field.txt_payment_btn_screen;
  let currency_symbol_html = '';
  if (window.charityDetail) {
    if (window.charityDetail.currency_symbol_html) currency_symbol_html = entityToChar(window.charityDetail.currency_symbol_html);
  }


  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="amount-donation-custom">
        <FadeInTransition show={show}>
          <div className="ch_heading_1 txt_primary">{txt_payment_btn_screen}</div>

          <div className="cstm_amount">
            <form className="form-group needs-validation" id={`amountForm-${uuid.current}`}>
              <div className="cstm_amount_icon currency-symbol">{currency_symbol_html}</div>
              <input
                className={`form-control ch_form_lg ${errorMsg ? 'activate_error' : ''}`}
                name="amount"
                placeholder="00"
                value={amount}
                onChange={(e) => onCustomAmount(e.target.value)}
              />
              {errorMsg ? <div className="activate_error">{errorMsg}</div> : null}
            </form>
          </div>

          <div className="customAmt-btn-div pt-5">
            <button
              className="btn ch_btn category-btn next_btn"
              label="Donate"
              disabled={ !(errorMsg==null && amount.length > 0) }
              onClick={() => onSubmitCustomAmount()}>
              Donate
            </button>
          </div>
          <div className="customAmt-btn-div">
            <button className="btn ch_btn category-btn" onClick={() => resetUI()}>
              Cancel
            </button>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
