import React from 'react';
import { resetUI } from './common';
import {
  DONATION_SECTION, store_webpay, setSection, setHeader, setLoading,
} from './store';

const showUI_Message = (section, message) => {
  setLoading(false);
  setHeader({ backSection: null, showCancel: false });
  setSection({ section, message });
};
export const showUI_MessageInfo = (message) => showUI_Message(DONATION_SECTION.MESSAGE_INFO, message);

export const showUI_MessageSuccess = (message) => showUI_Message(DONATION_SECTION.MESSAGE_SUCCESS, message);

export const showUI_MessageError = (message) => showUI_Message(DONATION_SECTION.MESSAGE_ERROR, message);

export function Message() {
  const unsubscribeRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const typeRef = React.useRef('');
  const messageRef = React.useRef('');

  const onShow = (showInfo, showSuccess, showError, message) => {
    // Show or Hide UI
    typeRef.current = '';
    if (showInfo) typeRef.current = 'INFO';
    if (showSuccess) typeRef.current = 'SUCCESS';
    if (showError) typeRef.current = 'ERROR';
    messageRef.current = message;
    setShow(showInfo || showSuccess || showError);
  };

  React.useEffect(() => {
    // Subscribe to current changes
    if (unsubscribeRef.current) unsubscribeRef.current();
    unsubscribeRef.current = store_webpay.subscribe((state, previousState) => {
      if (state.section !== previousState.section) {
        const condInfo = state.section === DONATION_SECTION.MESSAGE_INFO;
        const condSuccess = state.section === DONATION_SECTION.MESSAGE_SUCCESS;
        const condError = state.section === DONATION_SECTION.MESSAGE_ERROR;
        onShow(condInfo, condSuccess, condError, state.message);
      }
    });
    return () => {
      unsubscribeRef.current();
    };
  });

  const onClick = () => {
    setShow(false);
    resetUI();
  };

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="message info_msg">
        <div className="icon_svg">
          {typeRef.current === 'INFO'
            ? (
              <span className="icon-error-popup popup_icons_size">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
                <span className="path7" />
                <span className="path8" />
                <span className="path9" />
                <span className="path10" />
                <span className="path11" />
                <span className="path12" />
                <span className="path13" />
                <span className="path14" />
                <span className="path15" />
                <span className="path16" />
                <span className="path17" />
                <span className="path18" />
                <span className="path19" />
                <span className="path20" />
                <span className="path21" />
                <span className="path22" />
                <span className="path23" />
                <span className="path24" />
                <span className="path25" />
                <span className="path26" />
                <span className="path27" />
                <span className="path28" />
              </span>
            )
            : ''}

          {typeRef.current === 'SUCCESS'
            ? (
              <span className="icon-update-popup popup_icons_size">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
                <span className="path7" />
                <span className="path8" />
                <span className="path9" />
                <span className="path10" />
                <span className="path11" />
                <span className="path12" />
                <span className="path13" />
                <span className="path14" />
                <span className="path15" />
                <span className="path16" />
                <span className="path17" />
                <span className="path18" />
                <span className="path19" />
                <span className="path20" />
                <span className="path21" />
                <span className="path22" />
                <span className="path23" />
                <span className="path24" />
                <span className="path25" />
                <span className="path26" />
                <span className="path27" />
                <span className="path28" />
              </span>
            )
            : ''}

          {typeRef.current === 'ERROR'
            ? (
              <span className="icon-delete-popup popup_icons_size">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
                <span className="path7" />
                <span className="path8" />
                <span className="path9" />
                <span className="path10" />
                <span className="path11" />
                <span className="path12" />
                <span className="path13" />
                <span className="path14" />
                <span className="path15" />
                <span className="path16" />
                <span className="path17" />
                <span className="path18" />
                <span className="path19" />
                <span className="path20" />
                <span className="path21" />
                <span className="path22" />
                <span className="path23" />
                <span className="path24" />
                <span className="path25" />
                <span className="path26" />
                <span className="path27" />
                <span className="path28" />
              </span>
            )
            : ''}
        </div>

        <h4 className="message">{messageRef.current}</h4>

        {typeRef.current === 'SUCCESS'
          ? (
            <div className="customAmt-btn-div mt-5">
              <button className="btn ch_btn category-btn" onClick={() => onClick()}>
                Give again
              </button>
            </div>
          )
          : ''}

        {typeRef.current === 'ERROR'
          ? (
            <div className="customAmt-btn-div mt-5">
              <button className="btn ch_btn category-btn" onClick={() => onClick()}>
                Try again
              </button>
            </div>
          )
          : ''}
      </div>
    </div>
  );
}
