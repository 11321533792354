import React from 'react';
import { FadeInTransition } from './transition';
import { showUINext } from './common';
import PropTypes from 'prop-types';
Subscribe.propTypes = {
  show: PropTypes.bool
};

export function Subscribe(props) {
  const [show, setShow] = React.useState(false);

  const onShow = (showLocal) => {
    setShow(showLocal);
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onSubmit = (isSubcribe) => {
    window.userData.donor.email_opt_in = isSubcribe ? '1' : '0';
    showUINext();
  };

  let charity_name = '';
  if (window.charityDetail) {
    charity_name = window.charityDetail.charity_name;
  }

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="subscribe">
        <FadeInTransition show={show}>
          <div className="confirm_amount subscribe">
            <div className="display_amt">Subscribe ?</div>
            <div className="ch_heading_1 mt-3 mb-3">
              Do you want to subscribe to promotional emails from
              {' '}
              {charity_name}
              ?
            </div>

            <div className="backNext-btn-div pt-5">
              <button
                className="btn ch_btn backNext-btn"
                onClick={() => onSubmit(false)}
              >
                {' '}
                No
              </button>
              <button
                className="btn ch_btn backNext-btn"
                onClick={() => onSubmit(true)}
              >
                {' '}
                Yes
              </button>
            </div>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
