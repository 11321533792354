import React, { useCallback } from 'react';
import axios from 'axios';
import { FadeInTransition } from './transition';
import { showUI_MessageError, showUI_MessageSuccess } from './message';
import {
  store_webpay, setHeader, setLoading, SET_PAGE,
} from './store';
import { logError, LOG_ERROR_MSG, resetUI } from './common';
import { events } from './events';
import PropTypes from 'prop-types';

// let termsScroll = {}
const apiUrl = process.env.REACT_APP_BASE_URL;
const apiCharityCode = process.env.REACT_APP_CHARITY_CODE;

// save gift aid form donor details
const addDonor = async () => {
  try {
    const { contactDetailsFlow } = store_webpay.getState();
    const dataVal = {
      donor_firstname: window.userData.donor.firstName,
      donor_lastname: window.userData.donor.lastName,
      donor_email: window.userData.donor.email,
      donor_address: window.userData.donor.address,
      donor_postalcode: window.userData.donor.postcode,
      transaction_code: window.userData.transaction_code,
      email_opt_in: window.userData.donor.email_opt_in,
      payment_processor: window.charityDetail.payment_processor ? window.charityDetail.payment_processor : 'sumup',
      status: contactDetailsFlow ? 'CONTACT_REGISTERED' : 'GIFT_AID_REGISTERED',
    };
    const headers = { 'charity-code': apiCharityCode };
    const response = await axios.post(`${apiUrl}v4/webpay/donor/`, { data: dataVal }, { headers });
    const responseData = response.data.res;

    // show thankyou gift-aid message;
    if (responseData.status === 'Success') {
      showUI_MessageSuccess(
        contactDetailsFlow
          ? 'Thank you for giving! The charity will keep in touch with you.'
          : window.charityDetail.custom_text_field.txt_thankyou_giftaid,
      );
    }
  } catch (e) {
    console.log('e ', e.response.data);
    const msg = e.response.data ? `Response: '${e.response.data.res.msg}'` : '';

    showUI_MessageError(`Due to network issue we were unable to process your request! Please send us an email at contact@mydona.com. ${msg}`);
    logError(LOG_ERROR_MSG.NETWORK_ISSUE_DONOR_EMAIL.format(
      window.charityDetail.charity_name,
      window.userData.transaction_code,
      window.userData.donor.email,
    ), window.userData.transaction_code);
  }
};

TermsConditions.propTypes = {
  show: PropTypes.bool
};

export function TermsConditions(props) {
  const [show, setShow] = React.useState(false);

  const contentRef = React.useRef(); // Store to compute pagination
  const pageContentListRef = React.useRef([]);
  const [page, setPage] = React.useState(null);
  const { contactDetailsFlow } = store_webpay.getState();

  const onShow = (showLocal) => {
    setShow(showLocal);
  };

  const contentTitle = contactDetailsFlow ? 'Privacy Statement' : 'Terms and conditions';
  const getCurrentDate = () => {
    const dateObj = new Date();
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    return `${day}/${month}/${year}`;
  };

  const getWordList = useCallback(() => {
    const { contactDetailsFlow } = store_webpay.getState();
    // Set content
    let decodedText = '';
    if (contactDetailsFlow) {
      decodedText = decodeURIComponent(window.charityDetail.privacy_statement);
    } else {
      decodedText = decodeURIComponent(window.charityDetail.term_condition_content);
    }
    decodedText = decodedText.replace(/{{amount}}/g, window.userData.amount);
    decodedText = decodedText.replace(/{{organisation_name}}/g, window.charityDetail.charity_name);
    decodedText = decodedText.replace(/{{donation_date}}/g, getCurrentDate());
    decodedText = decodedText.replace(/{{date_agreed}}/g, getCurrentDate());
    decodedText = decodedText.replace(/{{donation_category}}/g, unescape(window.userData.category.name));

    const replacedText = decodedText.split('\n').join(' {{*}} \n');
    // replacedText = replacedText + replacedText + replacedText + replacedText
    // replacedText = replacedText.substring(0,10)
    let wordList = replacedText.split(' ');

    wordList = wordList.filter((item) => item.includes('{{*}}') === false);
    return wordList;
  }, []);

  const paginate = useCallback(() => {
    if (window.charityDetail == null) return;

    const wordList = getWordList();
    const limit = document.documentElement.clientHeight - 210;
    // const contentRefHeight = contentRef.current.clientHeight

    // If no word list do not process
    if (wordList.length === 0) return 0;

    contentRef.current.innerHTML = '';

    let content = '';
    const contentList = [];
    for (let i = 0; i < wordList.length; i++) {
      const word = wordList[i];
      content += `${word} `;
      contentRef.current.innerHTML = content;

      // Add words
      if (limit < contentRef.current.clientHeight) {
        contentList.push(content);
        content = '';
        contentRef.current.innerHTML = '';
      }
    }

    // Fill with remaining content
    contentList.push(content);

    // Store pages
    pageContentListRef.current = contentList;
    return 0;
  }, [getWordList]);

  React.useEffect(() => {
    const onSetPageEvent = ({ detail }) => onSetPage(detail.page);

    events.on('termsConditions:set', onSetPageEvent);

    return () => {
      events.off('termsConditions:set', onSetPageEvent);
    };
  }, []);

  React.useEffect(() => {
    const page = paginate();
    setPage(page);
    store_webpay.dispatch({ type: SET_PAGE, page });
  }, [contactDetailsFlow, paginate]);

  React.useEffect(() => {
    if (pageContentListRef.current.length === 0 && show) {
      const page = paginate();
      setPage(page);
      store_webpay.dispatch({ type: SET_PAGE, page });
    }
  }, [page, show, paginate]);

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onSetPage = (page) => {
    setPage(page);
    // For back button
    store_webpay.dispatch({ type: SET_PAGE, page });
  };

  const onCancel = () => {
    setShow(false);
    resetUI();
  };

  const onSubmitTermsCondition = () => {
    // GA Tag
    window.dataLayer.push({
      event: 'TermsandCondition',
      virtualPageURL: '/webpay/gaform/terms/',
      virtualPageTitle: 'GA form Terms and Condition.',
    });
    setHeader({ backSection: null, showCancel: false });
    setLoading(true);
    addDonor();
  };

  const pageCount = pageContentListRef.current.length;
  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="terms-conditions">
        <FadeInTransition show={show}>
          <div className="terms-condition d-flex flex-column">
            <h4>{contentTitle}</h4>
            <div
              ref={contentRef}
              className={`flex-grow-1 ${page == null ? 'invisible' : 'd-none'}`}
              style={{
                whiteSpace: 'pre-wrap', color: '#1F2D5F', fontSize: '18px', textAlign: 'justify',
              }}
            />

            {page != null
              ? (
                <div
                  className="flex-grow-1"
                  style={{
                    whiteSpace: 'pre-wrap',
                    color: '#1F2D5F',
                    fontSize: '18px',
                    textAlign: 'justify',
                    wordBreak: 'break-word',
                  }}
                >
                  {pageContentListRef.current[page]}
                </div>
              )
              : null}

            <div className="d-flex justify-content-center align-items-center pt-3">
              <button
                className="w-50 btn ch_btn backNext-btn btn-cancel"
                style={{ maxWidth: '200px' }}
                onClick={() => onCancel()}
              >
                      Cancel
              </button>

              {page < pageCount - 1
                ? (
                  <button
                    className="w-50 btn ch_btn backNext-btn btn-next"
                    style={{ maxWidth: '200px' }}
                    label="GiftAidForm"
                    onClick={() => onSetPage(page + 1)}
                  >
                          Next
                  </button>
                )
                : (
                  <button
                    className="w-50 btn ch_btn backNext-btn btn-confirm"
                    style={{ maxWidth: '200px' }}
                    label="GiftAidForm"
                    onClick={() => onSubmitTermsCondition()}
                  >
                          Confirm
                  </button>
                )}

            </div>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
