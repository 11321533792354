import React from 'react';
import { FadeInTransition } from './transition';
import { resetUI, showUINext } from './common';
import { store_webpay } from './store';
import PropTypes from 'prop-types';

ConfirmGiftAid.propTypes = {
  show: PropTypes.bool
};


export function ConfirmGiftAid(props) {
  const [show, setShow] = React.useState(false);
  const { contactDetailsFlow } = store_webpay.getState();

  const onShow = (showLocal) => {
    setShow(showLocal);
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  const onNoHandler = () => {
    setShow(false);
    resetUI();
  };
  const onYesHandler = () => {
    showUINext();
  };

  let txt_giftaid_screen_thankyou = '';
  let txt_giftaid_screen_heading = '';
  let txt_giftaid_screen_description = '';
  if (window.charityDetail && window.charityDetail.custom_text_field) {
    txt_giftaid_screen_thankyou = window.charityDetail.custom_text_field.txt_giftaid_screen_thankyou;
    txt_giftaid_screen_heading = window.charityDetail.custom_text_field.txt_giftaid_screen_heading;
    txt_giftaid_screen_description = window.charityDetail.custom_text_field.txt_giftaid_screen_description;
  }

  if (contactDetailsFlow) {
    txt_giftaid_screen_heading = 'Do you want to leave your contact details so the charity can keep in touch with you?';
    txt_giftaid_screen_description = '';
  }

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="confirm-giftaid">
        <FadeInTransition show={show}>
          <div className="gift_success_msg">
            <div className="icon_svg">
              <span className="icon-update-popup popup_icons_size">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
                <span className="path7" />
                <span className="path8" />
                <span className="path9" />
                <span className="path10" />
                <span className="path11" />
                <span className="path12" />
                <span className="path13" />
                <span className="path14" />
                <span className="path15" />
                <span className="path16" />
                <span className="path17" />
                <span className="path18" />
                <span className="path19" />
                <span className="path20" />
                <span className="path21" />
                <span className="path22" />
                <span className="path23" />
                <span className="path24" />
                <span className="path25" />
                <span className="path26" />
                <span className="path27" />
                <span className="path28" />
              </span>
            </div>

            <h6>{txt_giftaid_screen_thankyou}</h6>
            <hr />
            <h3>{txt_giftaid_screen_heading}</h3>
            <h5>{txt_giftaid_screen_description}</h5>

            <div className="d-flex justify-content-center backNext-btn-div pt-5">
              <button className="btn ch_btn backNext-btn w-50" onClick={() => onNoHandler()}>No</button>
              <button className="btn ch_btn backNext-btn w-50 btn-yes" onClick={() => onYesHandler()}>Yes</button>
            </div>
          </div>
        </FadeInTransition>
      </div>
    </div>
  );
}
