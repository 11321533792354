import React from 'react';
import axios from 'axios';
import { showUI_MessageError, showUI_MessageSuccess } from './message';
import { setLoading, setHeader, setContactDetails } from './store';
import { logError, LOG_ERROR_MSG, showUINext } from './common';
import { events } from './events';
import PropTypes from 'prop-types';

const apiUrl = process.env.REACT_APP_BASE_URL;
const apiCharityCode = process.env.REACT_APP_CHARITY_CODE;

// create payment checkout
const createCheckout = async () => {
  try {
    const dataVal = {
      charity_id: window.charityDetail.charity_id,
      amount: window.userData.amount,
      device_name: window.charityDetail.device_name,
    };
    const headers = { 'charity-code': apiCharityCode };
    const response = await axios.post(`${apiUrl}v4/webpay/sumup/checkout/`, { data: dataVal }, { headers });
    const responseData = response.data.res;

    // Show payment widget
    return responseData.data.checkout_id;
  } catch (e) {
    console.log('e ', e.response.data);
    const msg = e.response.data ? `Response: '${e.response.data.res.msg}'` : '';
    showUI_MessageError(`The checkout could not be created. Please reconnect via portal or contact admin. ${msg}`);
    logError(`failed to create checkout charityDetail=${JSON.stringify(window.charityDetail)} userData=${JSON.stringify(window.userData)}`, '');
    return null;
  }
};

const saveTransaction = async () => {
  try {
    // DONA - 1404
    // setLoading(true)
    // setLoading(false)
    const dataVal = {
      charity_id: window.charityDetail.charity_id,
      transaction_code: window.userData.transaction_code,
      amount: window.userData.amount,
      device_name: window.charityDetail.device_name,
      donation_category: unescape(window.userData.category.name),
      message: window.userData.message,
    };
    const headers = { 'charity-code': apiCharityCode };
    const response = await axios.post(`${apiUrl}v4/webpay/sumup/donation/`, { data: dataVal }, { headers });
    const responseData = response.data.res;

    if (responseData.status === 'Failure') {
      // GA Tag
      window.dataLayer.push({
        event: 'failedsavingTransaction',
        virtualPageURL: '/webpay/dona/failedsavedtransaction/',
        virtualPageTitle: 'Dona Failed Saving the Transaction',
      });
      //showUI_MessageError(responseData.msg);
      showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_donation);
      const msg = LOG_ERROR_MSG.TRANSACTION_PROCESSING.format(
        responseData.msg,
        window.charityDetail.charity_name,
        window.userData.transaction_code,
      );
      logError(msg, window.userData.transaction_code);
      return;
    }

    if (responseData.status !== 'Success') {
      // GA Tag
      window.dataLayer.push({
        event: 'failedNetwork',
        virtualPageURL: '/webpay/dona/failedNetwork/',
        virtualPageTitle: 'Dona Network Issue',
      });
      //showUI_MessageError('Something went wrong');
      showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_donation);
      logError(
        LOG_ERROR_MSG.NETWORK_ISSUE.format(window.charityDetail.charity_name, window.userData.transaction_code),
        window.userData.transaction_code,
      );
      return;
    }

    // Set Loading
    setLoading(false);

    // When category is giftAided
    if (window.charityDetail.giftaid_form_flag === 1 && window.userData.category.is_giftaided === 1) {
      // Donor not registered OR Donor has contact registered
      if (responseData.data.donor === null || responseData.data.donor.status === 'CONTACT_REGISTERED') {
        showUINext();
        return;
      }

      // Donor registered
      if (responseData.data.donor !== null) {
        window.dataLayer.push({
          event: 'DonorRegistered',
          virtualPageURL: '/webpay/donorregistered/',
          virtualPageTitle: 'User was Registered',
        });
        window.dataLayer.push({
          event: 'savedNormalTransaction',
          virtualPageURL: '/webpay/savednormaltrasaction/',
          virtualPageTitle: 'Save Normal Transaction',
        });
        showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_giftaid);
        return;
      }
    }

    // When category is not giftAided and Donor not registered - collect donor details
    if (window.charityDetail.collect_donor_details_flag
      && window.userData.category.is_giftaided === 0
      && responseData.data.donor == null) {
      setContactDetails(true);
      showUINext();
      return;
    }

    // GA Tag
    window.dataLayer.push({
      event: 'savedNormalTransaction',
      virtualPageURL: '/webpay/savednormaltrasaction/',
      virtualPageTitle: 'Save Normal Transaction',
    });

    // When giftaid disabled and category selected is_giftaided and donor is identified
    if (window.charityDetail.giftaid_form_flag === 0
        && window.userData.category.is_giftaided === 1
        && responseData.data.donor !== null) {
      showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_giftaid);
      return;
    }

    // show thankyou normal donation message;
    showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_donation);
  } catch (e) {
    //showUI_MessageError('Due to network issue we were unable to process your request! Please send us an email at contact@mydona.com');
    showUI_MessageSuccess(window.charityDetail.custom_text_field.txt_thankyou_donation);
    logError(
      LOG_ERROR_MSG.NETWORK_ISSUE.format(window.charityDetail.charity_name, window.userData.transaction_code),
      window.userData.transaction_code,
    );
  }
};


SumupWidget.propTypes = {
  show: PropTypes.bool
};

export function SumupWidget(props) {
  const [show, setShow] = React.useState(false);
  const [donateEnabled, setDonateEnabled] = React.useState(false);
  const [donateVisible, setDonateVisible] = React.useState(false);

  const onShow = async (showLocal) => {
    // Configure backButton
    if (showLocal && window.userData.checkout_id === null) {
      // GA Tag
      window.dataLayer.push({
        event: 'SumupWidgetLoaded',
        virtualPageURL: '/webpay/sumup/widgetloaded',
        virtualPageTitle: 'Sumup Widget Loaded.',
      });

      // Show spinner while creating checkout
      setDonateVisible(false);
      setDonateEnabled(false);

      // Get a checkout from API
      window.userData.checkout_id = await createCheckout();

      // Mount sumupCard
      if (window.sumUpSdkIsLoaded) {
        createSumUpCard();
      } else {
        if (window.sumUpSdkScriptElement) {
          window.sumUpSdkScriptElement.addEventListener('load', () => {
            createSumUpCard();
          });
        } else {
          console.log('Missing SumUp SDK');
        }
      }
    }

    // Show or Hide UI
    setShow(showLocal);
  };

  const createSumUpCard = () => {
    window.sumupCardInstance = window.SumUpCard.mount({
      checkoutId: window.userData.checkout_id,
      amount: window.userData.amount,
      currency: window.charityDetail.currency,
      locale: 'en-GB',
      onLoad() {
        setLoading(false);
        setDonateVisible(true);
        setDonateEnabled(true);

        // Event used to notify sumup is ready
        setTimeout(() => {
          // console.log("Triggering sumupWidget:ready")
          events.trigger('sumupWidget:ready');
        }, 2000);
      },
      onResponse: async (type, body) => {
        // if (type === "sent") {
        //   cardAndTransDetails.cardDigit = body.lastFourDigits;
        //   cardAndTransDetails.cardType = body.cardScheme;
        // }

        if (type === 'auth-screen') {
          // GA Tag
          window.dataLayer.push({
            event: 'OTPScreen',
            virtualPageURL: '/webpay/sumup/otpscreen/',
            virtualPageTitle: 'OTP screen',
          });
        }

        if (type === 'success') {
          // GA Tag
          window.dataLayer.push({
            event: 'PaymentSuccess',
            virtualPageURL: '/webpay/sumup/paymentsuccessful',
            virtualPageTitle: 'Payment Successful',
          });
          setLoading(true);

          // Retrieve checkout from API
          const headers = { 'charity-code': apiCharityCode };
          const response = await axios.get(`${apiUrl}v4/webpay/sumup/checkout/${body.id}?charity_id=${window.charityDetail.charity_id}`, { headers });
          const checkoutResponse = response.data.res.data;

          const status = checkoutResponse.status.toLowerCase();
          if (status === 'paid') {
            window.userData.transaction_code = checkoutResponse.transactions[0].transaction_code;
            // NOTE: This is not used but lets keep it here
            window.userData.transaction = { ...body };

            // unmount the widget
            window.sumupCardInstance.unmount();

            // saveTransaction
            saveTransaction();
          } else {
            // GA Tag
            window.dataLayer.push({
              event: 'PaymentFailed',
              virtualPageURL: '/webpay/sumup/paymentfailed/',
              virtualPageTitle: 'Sumup Payment Failed',
            });

            setDonateEnabled(true);
            showUI_MessageError('There was an error processing your donation. Please try again or contact the charity to report the issue.');
            window.sumupCardInstance.unmount();

            // Log response
            if (body.transaction_code) {
              const msg = LOG_ERROR_MSG.DONATION_PROCESSING_TRANSACTION_CODE.format(window.charityDetail, body.transaction_code);
              logError(msg, body.transaction_code);
            } else logError(`${LOG_ERROR_MSG.DONATION_PROCESSING.format(JSON.stringify(window.charityDetail))} userData=${JSON.stringify(window.userData)} checkout=${JSON.stringify(checkoutResponse)}`);
          }
        } else if (type === 'invalid') {
          setDonateEnabled(true);
          setLoading(false);
        } else if (type === 'error') {
          // GA Tag
          window.dataLayer.push({
            event: 'PaymentFailed',
            virtualPageURL: '/webpay/sumup/paymentfailed/',
            virtualPageTitle: 'Sumup Payment Failed',
          });

          showUI_MessageError('There was an error processing your donation. Please try again or contact the charity to report the issue.');
          window.sumupCardInstance.unmount();

          setDonateEnabled(true);
          logError(LOG_ERROR_MSG.DONATION_PROCESSING_SUMUP.format(body.message, window.charityDetail.charity_name));
        }
      },
      showSubmitButton: false,
      showFooter: false,
    });
  };

  const submitPaymentWidget = () => {
    // Show loading
    setShow(false);
    // DONA - 1404
    // setLoading(true)
    setDonateVisible(false);
    // setDonateEnabled(false)

    // Hide back button
    setHeader({ backSection: null, showCancel: false });

    // GA tag
    window.dataLayer.push({
      event: 'sumupDonate',
      virtualPageURL: '/webpay/sumupwidget/paymentprocessing/',
      virtualPageTitle: 'User entered card details and Payment Processing',
    });

    // submit request over sumup
    window.sumupCardInstance.submit();
  };

  React.useEffect(() => {
    if (show !== props.show) onShow(props.show);
  });

  return (
    <div className={show ? 'content-padding' : 'd-none'}>
      <div className="sumup-widget widget__cntr">
        <div id="sumup-card" style={{ minHeight: '410px' }} />
        <div className="otherAmt-btn-div">
          <button
            className={`btn ch_btn category-btn ${donateVisible ? '' : 'd-none'}`}
            disabled={!donateEnabled}
            onClick={() => submitPaymentWidget()}
          >
            Donate
          </button>
        </div>
      </div>
    </div>
  );
}
